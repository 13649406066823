window.angular.module('MyHippoProducer.Components').directive('hcButton', function () {
    return {
        restrict: 'E',
        scope: {
            disabled: '<',
            click: '&',
            type: '@'
        },
        transclude: true,
        templateUrl: 'components/button/button.html',
        controller: function ($scope) {
            $scope.type = $scope.type || 'primary';
        }
    };
});
